@import '../../globals/mixins';

.inputRange {
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 0;
  list-style: none;
  display: inline-flex;
  float: left;
  width: 100%;
  max-width: calc(100% - #{$size-s});
  margin: $size-l $size-xs 0;
  position: relative;
  bottom: 0;
  border: 1px solid $color-subtl1;
  border-radius: $size-xs;
  @include transition-all();

  fieldset {
    border: 1px solid $color-subtl1;
    border-radius: $size-xs;
    padding: $size-s;
    border: none;
    width: 100%;
    &::after {
      content: ' ';
      border-bottom: 1px solid $color-subtl1;
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      width: $size-m;
      transform: translate(-50%, -50%);
    }
    legend {
      pointer-events: none;
      position: absolute;
      bottom: $size-xxl;
      left: 0;
      width: 100%;
      left: 0;
      text-align: left;
    }
    input {
      @include transition-all();
      border: 1px solid $color-subtl3;
      color: $color-grey;
      background: $color-subtl3;
      border: none;
      border-radius: $size-xs;
      margin: 0;
      padding: 0 $size-xs;
      height: $size-m;
      width: $size-xl;
      text-align: center;
      &:hover {
        bordercolor: $color-subtl1;
      }
      &:first-of-type {
        float: left;
      }
      &:last-of-type {
        float: right;
      }
    }
  }

  @include screen-s() {
    max-width: calc((100% / 2) - #{$size-s});
  }

  @include screen-m() {
    max-width: calc((100% / 3) - #{$size-s});
  }

  &:hover {
    border-color: $color-dark;
  }

  &.active {
    border-color: $color-dark;
    fieldset {
      &::after {
        border-color: $color-dark;
      }
      input {
        color: $color-white;
        background: $color-dark;
      }
    }
  }
}
