@import '../../globals/mixins';

.figure {
  // pointer-events: none;
  display: inline-block;
  margin-top: $size-xs;
  margin-bottom: $size-xs;
  min-width: 25%;
  text-align: left;
  vertical-align: top;

  a {
    text-decoration: none;
    color: $color-dark;
    caption {
      color: $color-dark;
    }
  }

  figure {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
    margin: 0;
    white-space: normal;

    & > div {
      background: $color-subtl3;
      border-radius: $size-xs;
      position: relative;
      height: auto;
      min-height: 48px;
      min-width: 48px;
      width: 100%;
      overflow: hidden;
      margin-bottom: $size-xs;
      & > span {
        &.icon {
          position: absolute;
          height: 60%;
          width: 60%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @include screen-l {
            transform: translate(-50%, -56%);
            height: 45%;
            width: 45%;
          }
        }
      }

      h3,
      p {
        position: absolute;
        transform: translateY(56%);
        font-weight: 200;
        @include font-size-h2;
        top: 50%;
        width: 100%;
        text-align: center;
      }

      & > button {
        position: absolute;
        bottom: $size-xxs;
        left: $size-ss;
      }

      & > div {
        float: left;
      }

      img {
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        @include no-select();
        @include no-drag();
      }
    }

    figcaption {
      overflow: hidden;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &.dark {
    figure {
      & > div {
        color: $color-white;
        & > span {
          &.icon {
            & > svg * {
              stroke: $color-white;
            }
          }
        }
      }
    }
  }

  &.border {
    @include rounded;
    border: 1px solid $color-subtl1;
    figure {
      & > div {
        background: $color-white;
        // & > span {
        // 	&.icon {
        // 		& > svg * {
        // 			stroke: $color-white;
        // 		}
        // 	}
        // }
      }
    }
  }

  &.tiny {
    figure {
      & > div {
        float: left;
        width: 25%; //calc(25% - #{$size-xs});
        padding-bottom: 25%; //calc(25% - #{$size-xs}); // 1:1 ratio
        margin: 0 $size-xs 0 0;
        @media (min-width: $screen-m) {
          margin: 0 $size-s 0 0;
        }
      }
    }
    &.round {
      figure {
        & > div {
          padding-bottom: 0;
          border-radius: 24px;
          height: $size-l;
          width: $size-l;
        }
      }
    }
  }

  &.small {
    figure {
      & > div {
        float: left;
        width: calc(33.333333% - #{$size-xs});
        padding-bottom: calc(33.333333% - #{$size-xs}); // 1:1 ratio
        margin: 0 $size-xs 0 0;
        @media (min-width: $screen-m) {
          width: calc(50% - #{$size-xs});
          padding-bottom: calc(50% - #{$size-xs}); // 1:1 ratio
          margin: 0 $size-s 0 0;
        }
      }
    }
  }

  &.medium {
    &.float {
      min-width: 0;
      width: 100%;
      padding: 0 8px;
    }

    figure {
      & > div {
        padding-bottom: 100%; // 1:1 ratio
        @media (min-width: $screen-m) {
          padding-bottom: calc((100% / 4) * 3); // 4:3 ratio
        }
      }
    }
  }

  &.full {
    width: 100%;
    &.small {
      figure {
        & > div {
          padding: 0;
          height: $size-xl;
          width: $size-xl;
        }
      }
    }
  }

  &.large {
    width: 100%;
    figure {
      & > div {
        padding-bottom: calc(100% / (4 / 3));

        // @media (min-width: $screen-m) {
        //   padding-bottom: calc(100% / (1/1));
        // }

        @include screen-l {
          padding-bottom: calc(100% / (9 / 3.5));
        }
      }
    }
  }

  &.ratio-4-3 {
    width: 100%;
    figure {
      & > div {
        @include element-height-16-9;
        @include screen-l {
          @include element-height-4-3;
        }
      }
    }
  }

  &.ratio-9-3 {
    width: 100%;
    figure {
      & > div {
        @include element-height-16-9;
        @include screen-l {
          @include element-height-9-3;
        }
      }
    }
  }

  &.ratio-16-9 {
    width: 100%;
    figure {
      & > div {
        @include element-height-16-9;
        // & > span {
        // 	&.icon {
        // 		transform: translate(-50%, -56%);
        // 		height: 50%;
        // 		width: 50%;

        // 	}
        // }
      }
    }
  }

  &.hero {
    width: 100%;
    figure {
      & > div {
        padding-bottom: calc(100% / (3 / 4));

        @media (min-width: $screen-m) {
          padding-bottom: calc(100% / (1 / 1));
        }

        @media (min-width: $screen-l) {
          padding-bottom: calc(100% / (9 / 3.5));
        }
      }
    }
  }

  &.round {
    width: 100%;
    figure {
      & > div {
        border-radius: 50%;
        width: 100%;
      }
    }
  }

  &.center {
    figure {
      figcaption {
        width: 100%;
        text-align: center;
      }
    }
  }

  &.float {
    margin: 0 $size-xs;
    // &.medium {
    //   width: 25%;
    //   max-width: 320px;
    // }
  }

  &.gradient {
    figure {
      & > div {
        background: $color-gradient-ttb;
        img {
          opacity: 0.5;
        }
        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &.pointer {
    cursor: pointer;
    figure {
      & > div {
        cursor: pointer;
      }
    }
  }

  &.hover {
    &:hover {
      figure {
        & > div {
          img {
            transform: scale(1.2);
          }
        }
      }
      &.large {
        figure {
          & > div {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
}
