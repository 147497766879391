@import '../../globals/mixins';

.layer {
  @include transition-all();
  height: 0;
  overflow: visible;
  position: fixed;
  left: 0;
  top: 50%;
  width: 100vw;
  z-index: 4;

  &:before {
    @include transition-all();
    background: rgba(255, 255, 255, 0);
    content: ' ';
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  & > div {
    @include transition-all;
    @include rounded;

    height: auto;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    left: 50%;
    margin: 0;
    text-align: left;
    transform: translate(-50%, -50%);
    width: calc(100% - #{$size-xs});
    z-index: 2;

    @include screen-s {
      width: calc(100vw - 64px);
    }

    // padding: 0 $size-s;
    // @include screen-m() {
    // 	padding: 0 $size-l;
    // }

    & > button:first-of-type {
      border: 1px solid $color-subtl1;
      height: $size-m;
      position: absolute;
      left: $size-s;
      top: $size-s;
      width: $size-m;
      z-index: 3;
      &:hover {
        border-color: $color-dark;
      }
    }
  }

  &.simple {
    & > div {
      & > button:first-of-type {
        left: auto;
        right: $size-s;
      }
    }
    &:not(.large) {
      & > div {
        & > button:first-of-type {
          border: none;
          box-shadow: none;
        }
      }
    }
    &.large {
      & > div {
        & > button:first-of-type {
          right: $size-m;
          top: $size-m;
        }
      }
    }
  }

  &.small {
    width: 0%;
    @include screen-l {
      & > div {
        width: 75%;
        max-width: 956px;
      }
    }
  }

  &.medium {
    width: 0%;
    @include screen-l {
      & > div {
        max-width: 1148px;
      }
    }
  }

  &.large {
    width: 0%;

    & > div {
      max-width: 1376px;
    }
  }

  &.auto {
    width: 0%;

    & > div {
      width: auto;
      @include screen-m {
        min-width: 25%;
      }
    }
  }

  &.center {
    & > div {
      text-align: center;
    }
  }

  &.bg {
    & > div {
      background: $color-white;
      & > div {
        padding: 0 $size-m;
        position: relative;
        height: 100%;
        overflow-y: auto;
        width: 100%;
        max-height: calc(90vh - (#{$size-ml} * 2));
        // @include screen-s {
        //   padding: 0 $size-m;
        // }
        @include screen-l {
          padding: 0 $size-l;
        }
      }
    }
  }

  &.open {
    & > div {
      opacity: 1;
      padding: $size-xl $size-s;
      pointer-events: all;
      max-height: 100vh;
      overflow-y: auto;
      @include screen-m {
        padding: $size-l;
      }
    }

    &.bg {
      & > div {
        @include shadow-s;
        overflow: hidden;
        padding: $size-l 0;
        // padding: $size-s 0;
        // @include screen-s {
        // 	padding: $size-m 0;
        // }
        // @include screen-l {
        // 	padding: $size-l 0;
        // }
      }

      &.overflow {
        & > div {
          padding-top: $size-ml;
          @include fade-top-bottom;
          &::before {
            top: $size-m;
          }
          &::after {
            bottom: calc(#{$size-l} - 1px);
          }
          // &::before {
          // 	top: $size-s;
          // }
          // &::after {
          // 	bottom: calc(#{$size-s} - 1px);
          // }
          // @include screen-s {
          // 	&::before {
          // 		top: $size-m;
          // 	}
          // 	&::after {
          // 		bottom: calc(#{$size-m} - 1px);
          // 	}
          // }
          // @include screen-l {
          // 	&::before {
          // 		top: $size-l;
          // 	}
          // 	&::after {
          // 		bottom: calc(#{$size-l} - 1px);
          // 	}
          // }
          // & > div {
          // 	padding-top: $size-xs;
          // 	padding-bottom: $size-m;
          // }
        }
      }
    }

    &.full > div {
      max-height: 100vh;
      height: 100vh;
      width: 100vw;
    }

    &::before {
      background: rgba(255, 255, 255, 0.8);
      pointer-events: all;
    }
  }
}
