@import '../../globals/mixins';

.accountLayer {
  & > div:first-of-type,
  & > div:last-of-type {
    @include rounded;
    @include shadow-m;
    background: $color-white;
  }
  & > div:first-of-type {
    padding: $size-xs $size-s;
    & > div:last-of-type {
      // text-align: right;
      & > button {
        position: relative;
        right: $size-s;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  & > div:nth-of-type(2) {
    height: $size-s;
  }

  & > div:last-of-type {
    min-height: 50vh;
    max-height: 80vh;
    height: auto;
    overflow: auto;
    padding-top: $size-m;
    padding-bottom: $size-s;

    h1 {
      & > div {
        height: $size-xl;
        width: $size-xl;
      }
      align-items: center;
      display: flex;
      // @include font-size-hero;
      padding: 0;
    }
    p {
      padding: 0;
    }

    form {
      padding-bottom: $size-m;

      // fieldset {
      // 	padding: $size-m 0 0;
      // }

      & > small,
      & > span {
        display: block;
        float: left;
        width: 100%;
      }

      & > small {
        color: $color-red;
      }

      & > span {
        color: $color-grey;
        margin-bottom: $size-s;
      }

      & > button {
        transform: translate(-50%, $size-xs);
        position: relative;
        left: 50%;
      }
    }
  }
}
