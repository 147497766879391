@import '../../globals/mixins';

.loading {
  width: $size-xl;
  height: $size-xl;
  
  @keyframes offset_normal {
    to {
      stroke-dashoffset: 0;
    }
  }

  svg {
    & > path {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        stroke-dasharray: 160;
        stroke-dashoffset: 800;
        animation: offset_normal 5s ease-in-out forwards;
        animation-iteration-count: infinite;
      }
      &:nth-child(3) {
        stroke-dasharray: 80;
        stroke-dashoffset: 400;
        animation: offset_normal 5s ease-in-out forwards;
        animation-iteration-count: infinite;
      }
    }
  }

  &.bg {
    svg {
      & > path:first-child {
        display: block;
      }
    }
  }

  &.overlay {
    @include fade-in;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 99999999;
  }
}
