@import '../../globals/mixins';

.toast {
  @include rounded;
  @include shadow-s;
  @include transition-all;
  align-items: center;
  background: $color-white;
  display: inline-flex;
  height: calc(#{$size-l} * 2);
  overflow: hidden;
  max-width: $grid-max-width;
  padding: 0 $size-s;
  position: fixed;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: space-between;
  width: calc(100vw - #{$size-s});
  z-index: 2;

  &.active {
    bottom: $size-m;
  }

  & > small {
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 66%;
  }

  & > div {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    & > small {
      align-items: center;
      cursor: default;
      display: none;
      font-size: $font-size-xs;
      min-height: $size-m;
      margin-right: $size-s;
      position: relative;
      text-align: center;
      // a {
      //   text-decoration: none;
      // }
      // &::after {
      //   border-bottom: 2px solid $color-dark;
      //   bottom: 0;
      //   color: $color-dark;
      //   content: ' ';
      //   position: absolute;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   width: $size-m;
      // }
    }
    // & > button {
    //   font-size: $font-size-xs;
    // }
  }

  & > button {
    justify-self: flex-end;
    margin-left: $size-s !important;
  }

  @include screen-s {
    width: calc(100vw - #{$size-m});
  }

  @include screen-m {
    & > div {
      & > small {
        display: inline-flex;
      }
    }
  }

  @include screen-l {
    padding: 0 $size-m;
    width: calc(100vw - #{$size-l});
    & > div {
      & > small {
        margin-right: $size-m;
      }
    }
    & > button {
      margin-left: $size-m !important;
    }
  }
}
