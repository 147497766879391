@import '../../globals/mixins';

.stage {
  background: $color-subtl3;
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  min-height: calc(100vw / (3 / 4));

  @include screen-m {
    min-height: calc(100vw / (1 / 1));
  }

  @include screen-l {
    min-height: calc(100vw / (9 / 3.5));
  }

  figure {
    & > div {
      & > span {
        position: absolute;
      }
    }
    img {
      border-radius: 0;
    }
    figcaption {
      background: transparent
        linear-gradient(0deg, #00164084 0%, #00040d00 100%) 0% 0% no-repeat
        padding-box;
      bottom: 0;
      padding-bottom: $font-size-xl;
      color: $color-white;
      position: absolute;
      width: 100vw;
      a {
        white-space: nowrap;
      }
    }
  }

  &.hero {
    figure {
      figcaption {
        h1,
        h2 {
          @include font-size-hero;
          padding-bottom: $size-ss;
        }
      }
    }
  }
}
