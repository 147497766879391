@import '../../globals/mixins';

.inputToggle {
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 0;
  list-style: none;
  display: inline-flex;
  float: left;
  width: 100%;
  max-width: calc(100% - #{$size-s});
  margin: $size-l $size-xs 0;
  position: relative;
  bottom: 0;
  border: 1px solid $color-subtl1;
  border-radius: $size-xs;
  @include transition-all();

  fieldset {
    border: 1px solid $color-subtl1;
    border-radius: $size-xs;
    padding: $size-s;
    border: none;
    width: 100%;
    height: $size-xl;
    legend {
      pointer-events: none;
      position: absolute;
      bottom: $size-xxl;
      left: 0;
      width: 100%;
      left: 0;
      text-align: left;
    }
    label {
      color: $color-grey;
      padding-left: $size-xl;
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  @include screen-s() {
    max-width: calc((100% / 2) - #{$size-s});
  }

  @include screen-m() {
    max-width: calc((100% / 3) - #{$size-s});
  }

  .switch {
    @include transition-all();
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -59%);
    text-indent: $size-xl;
    line-height: 0.8;
    color: $color-grey;
    border: 1px solid $color-subtl1;
    border-radius: $size-xs;
    background-color: $color-white;
    width: $size-l;
    height: $size-s;
    white-space: nowrap;
    &:hover {
      border-color: $color-dark;
    }
    &::before {
      @include transition-all();
      position: absolute;
      content: '';
      border-radius: $size-xs;
      height: 10px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: $color-subtl1;
    }
  }

  input:checked + .switch:before {
    transform: translateX(22px);
  }

  &:hover {
    border-color: $color-dark;
  }

  &.active {
    border-color: $color-dark;
    fieldset {
      label {
        color: $color-dark;
      }
      input {
        color: $color-white;
        background: $color-dark;
      }
    }
    .switch {
      color: $color-dark;
      border-color: $color-dark;
      &::before {
        background-color: $color-dark;
      }
    }
  }
}
