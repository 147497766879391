@import '../../globals/mixins';

.pageDefault {
  overflow: hidden;
  height: auto;

  & > button {
    position: fixed;
    bottom: $size-m;
    left: 50%;
    min-width: calc(75% - #{$size-m});
    transform: translateX(-50%);
    opacity: 1;
    pointer-events: all;
    z-index: 2;
    @include screen-s {
      min-width: calc(33.333333% - #{$size-m});
    }
    @include screen-m {
      min-width: calc(25% - #{$size-m});
      bottom: $size-l;
    }
    @include screen-l {
      min-width: calc(12.5% - #{$size-m});
    }
  }

  .help {
    left: unset;
    min-width: 0;
    right: $size-s;
    transform: none;
    z-index: 3;

    @include screen-m {
      right: $size-m;
    }

    span {
      margin: 0 0 0 $size-s !important;
      &:nth-child(2) {
        margin-right: $size-xs !important;
      }
    }
    &:hover {
      path {
        fill: $color-white;
      }
    }
  }

  &.atAnchor {
    & > button {
      opacity: 0;
      pointer-events: none;
    }
  }
}
