@import '../../globals/mixins';

.autocomplete {
  // @include transition-all();
  transition: max-height $speed-default;
  background: $color-white;
  border-radius: $size-xs;
  height: calc(100vh - (#{$size-s} * 9));
  max-height: 0;
  // opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2;

  ul {
    @include no-list();
    @include autoscroll();
    padding: $size-s;
    list-style: none;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s ease-in-out;

    @include screen-m() {
      padding: $size-m $size-l;
    }

    li {
      @include transition-all();
      display: inline-block;
      margin: 0;
      padding: $size-xs 0;
      text-align: left;
      width: 100%;
      cursor: pointer;
      user-select: none;

      a {
        display: inline-flex;
        align-items: center;
        width: 100%;
        pointer-events: none;
        color: $color-dark;
        font-size: $font-size-s;
        text-decoration: none;
        & > span {
          display: inline-flex;
          align-items: center;
          width: auto;
        }
        & > span:first-of-type {
          margin-right: $size-s;
          min-height: $size-m;
          min-width: $size-m;
        }
        & > span:nth-of-type(3) {
          &::before {
            content: ', \00a0';
          }
          color: $color-grey;
        }
      }
      &:hover {
        a {
          color: $color-blue;
        }
      }

      &.header {
        cursor: default;
        user-select: none;
        font-size: $font-size-sm;
        font-weight: bold;
        margin-bottom: $size-xs;
      }
    }
  }

  &.open {
    pointer-events: all;
    opacity: 1;
    max-height: 100%;
  }
}

.loaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  z-index: -1;

  // transition opacity
  transition: opacity;
  transition: opacity 0.2s ease-in-out;
}

.noResults {
  padding-left: $size-s;
  padding-right: $size-s;
  font-size: $font-size-xs;
  color: $color-grey;
  user-select: none;

  @include screen-m() {
    padding-top: $size-xs;
  }
}
