@import '../../globals/mixins';

.form {
  @include transition-all;
  position: relative;
  h1 {
    & > div {
      height: $size-xl;
      width: $size-xl;
    }
    align-items: center;
    display: flex;
    // @include font-size-hero;
    padding: 0;
  }

  h2 {
    margin-top: 0;
    padding-top: 0;
  }

  p {
    padding: 0;
    text-align: left;
    width: 100%;
  }

  button {
    float: left;
    margin-right: $size-s;
    &:nth-of-type(3) {
      float: right;
      margin-right: 0;
    }
  }

  .alert {
    color: $color-red;
  }

  .footer {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  &.center {
    .footer {
      text-align: center;
      button {
        float: none;
        margin: 0 $size-xs;
      }
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;

    // .footer {
    // 	button {
    // 		opacity: .3;
    // 		pointer-events: none;
    // 	}
    // }
  }

  &.float {
    .footer {
      padding-left: $size-xs;
    }
  }
}
