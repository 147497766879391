@import '../../globals/mixins';

.searchLayer {
  @include transition-all();
  height: $size-xl;
  overflow: visible;
  position: fixed;
  left: 0;
  top: -#{$size-xl};
  right: 0;
  z-index: 4;

  &::before {
    @include transition-all();
    position: fixed;
    content: ' ';
    pointer-events: none;
    background: rgba(255, 255, 255, 0.8);
    opacity: 0;
    // backdrop-filter: blur($size-s) opacity(0);
    // @include bg-blur();
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-grey;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-grey;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-grey;
  }

  // Row
  & > div:first-child {
    // Col
    & > div {
      & > button {
        @include shadow-m();
        // height: $size-xl;
        margin: 0;
        text-transform: capitalize;
        transform: translateY(-50%);
        white-space: nowrap;
        overflow: hidden;
        justify-content: space-around;
        border: 1px solid transparent;
        z-index: 3;
        font-size: $font-size-s;

        @include screen-m() {
          font-size: $font-size-s;
        }

        @include screen-l() {
          font-size: $font-size-s;
        }

        & > div {
          justify-content: space-around;
        }

        span {
          overflow: hidden;
          // max-width: 70%;
        }

        &:hover {
          @include shadow-m();
          background: $color-white;
          border-color: $color-dark;
        }

        &:nth-child(2) {
          position: absolute;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &.initial:not(.open) {
    //:not(.scrolled)
    position: absolute;
    top: calc((100vw / (3 / 4)));
    opacity: 1;
    @include screen-m() {
      top: calc((100vw / (1 / 1)));
    }

    @include screen-l() {
      top: calc((100vw / (9 / 3.5)));
    }
  }

  &.fullSearch:not(.open) {
    //:not(.scrolled)
    position: absolute;
    opacity: 1;
  }

  // &.initial.scrolled:not(.open) {
  // 	pointer-events: none;
  // 	position: fixed;
  // 	opacity: 0;
  // 	top: calc(50vh - #{$size-xl});
  // }

  &.filter {
    top: calc(100vh - #{$size-xl});
    &:not(.open) {
      // Row
      & > div:first-child {
        // Col
        & > div {
          & > button {
            overflow: visible;
            width: calc(50% - #{$size-s});
            margin: 0 $size-xs;
            & > div > span {
              margin: 0;
              width: auto;
            }
            &:hover {
              border-color: rgba(0, 0, 0, 0);
            }

            &:nth-child(2) {
              position: relative;
              opacity: 1;
              pointer-events: all;
              margin: 0 $size-xs;
              width: calc(50% - #{$size-s});
            }
          }
        }
      }
    }
  }

  &.fullSearch {
    top: 220px;
    &:not(.open) {
      // Row
      & > div:first-child {
        // Col
        & > div {
          & > button {
            overflow: visible;
            width: 100%;
            margin: 0 $size-xs;
            & > div > span {
              margin: 0;
              width: auto;
            }
            &:hover {
              border-color: rgba(0, 0, 0, 0);
            }

            &:nth-child(1) {
              & > div {
                justify-content: flex-start !important;
              }
            }

            &:nth-child(2) {
              position: fixed;
              opacity: 1;
              pointer-events: all;
              margin: 0 $size-xs;
              width: 100%;
              max-width: 180px;
              bottom: $size-l;
              // align center
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  .smartSearch {
    display: none;
    margin-top: -$size-xxxl;
    margin-bottom: $size-m;
    position: relative;
    @include transition-all();
    color: transparent;
    opacity: 0;
    pointer-events: none;
    background: $color-white;
    border-color: rgba(0, 0, 0, 0);
    height: 0;
    width: 0;
    overflow: hidden;
    top: -$size-s;

    padding-left: $size-xs;
    z-index: 3;
    text-overflow: ellipsis;
    font-size: $font-size-s;

    @include screen-m() {
      left: $size-m;
      padding-left: $size-xs;
    }

    // @include screen-l() {
    //   font-size: $font-size-s;
    // }
  }

  & > div {
    @include transition-all();
    & > div {
      @include transition-all();
      & > div {
        display: flex;
        justify-content: center;
        border-radius: $size-xs;
        opacity: 0;
        background: white;
        position: relative;
        text-align: left;
        top: 0;
        width: 100%;
        // max-height: 1500px;
        overflow: hidden;
        // overflow-y: auto;
        // scroll-behavior: smooth;
        height: 0;
        @include shadow-s();
        @include transition-all();
        @include screen-m() {
          @include shadow-m();
        }

        & > button {
          position: absolute;
          bottom: $size-s;
        }
      }
    }
  }

  .fade {
    @include transition-all();
    pointer-events: none;
    opacity: 0;
    bottom: $size-xl;
    padding-top: $size-xl;
    padding-bottom: $size-m;
    z-index: 1;
    position: absolute;
    height: auto;
    left: 50%;
    width: 0;
    overflow: hidden;
    button {
      margin: 0;
      opacity: 0;
      transition: all 0.2s ease-out;
    }
    &::before {
      position: absolute;
      content: ' ';
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 70%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: $size-xs;
      width: 0;
      overflow: hidden;
      left: 50%;
      bottom: 0;
      height: 0;
    }
    @include screen-m() {
      bottom: calc(#{$size-l} * 2);
      padding-bottom: $size-l;
    }
  }
  // .options {
  // 	display: none;
  // }

  &.focus {
    .fade,
    .options {
      opacity: 0;
    }
  }

  &.open {
    top: $size-l;
    height: 100vh;

    @include screen-m() {
      top: $size-xl;
    }

    &::before {
      opacity: 1;
      pointer-events: all;
    }

    & > div:first-child {
      // Row
      & > div {
        // Row
        & > button:first-of-type {
          background: $color-white !important;
          svg {
            stroke: $color-dark;
          }
          &::before {
            content: none !important;
          }
          &:hover {
            border-color: rgba(0, 0, 0, 0);
            svg {
              stroke: $color-dark !important;
            }
          }
        }
      }
    }

    .searchInputButton {
      cursor: default !important;

      svg {
        cursor: pointer !important;
      }
    }

    .smartSearch {
      display: block;
      left: $size-s;
      width: calc(100% - #{$size-s + $size-s + $size-m + $size-sm});
      pointer-events: all;
      color: $color-dark;
      opacity: 1;
      height: $size-xl;
    }

    .fade {
      pointer-events: all;
      opacity: 1;
      height: auto;
      left: $size-xs;
      width: 100%;

      &::before {
        background: linear-gradient(
          0deg,
          white 70%,
          rgba(255, 255, 255, 0) 100%
        );
        width: calc(100% - #{$size-s});
        left: $size-xs;
        height: 100%;
      }
    }

    &:not(.focus) {
      .fade {
        button {
          opacity: 1;
        }
      }
    }

    & > div {
      & > div {
        & > div {
          top: -$size-s;
          opacity: 1;
          height: calc(100vh - (#{$size-s} * 8));
          @include screen-m() {
            height: 55vh;
            & > button {
              bottom: $size-l;
            }
          }
        }
      }
    }
  }
}

.showResultsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchInputButton {
  & > div {
    padding-left: $size-s;
    padding-right: $size-s;

    & > span {
      margin: 0 !important;
    }
  }
}

.bottomDescription {
  padding: $size-l calc((100% / 8) - #{$size-xs});
  font-size: $font-size-xs;
  font-weight: 400;
  opacity: 0.5;
}
