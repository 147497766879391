@import '../../globals/mixins';

.contentbox {
  @include rounded;
  background: $color-subtl3;
  position: relative;
  margin-bottom: $size-s;
  width: 100%;
  height: auto;

  &:not(.scrollable):not(.autoHeight):not(.fullHeight) {
    min-height: calc((100vw - #{$size-m}) / (16 / 9));
  }

  &.img {
    height: calc((100vw - #{$size-m}) / (4 / 3));
  }

  & > div {
    height: 100%;
    padding: $size-s;
    position: relative;
    width: 100%;
    h1,
    p {
      padding-left: $size-m;
      padding-right: $size-m;
    }
  }

  @include screen-s {
    &.img {
      height: calc((100vw - #{$size-m}) / (16 / 9));
    }
  }

  @include screen-l {
    &:not(.autoHeight):not(.fullHeight):not(.scrollable) {
      min-height: 0;
      margin-bottom: 0;
      padding-bottom: calc(100% / (4 / 3));
    }

    &.img {
      height: auto;
    }
    & > div {
      padding: $size-l;
      position: absolute;
      h1,
      p {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.center,
  &.vCenter {
    &:not(.autoHeight):not(.fullHeight) {
      & > div {
        @include screen-s {
          top: 50%;
          transform: translateY(-60%);
          position: absolute;
        }
      }
    }
  }

  &.vCenter {
    & > div {
      height: auto;
      text-align: left;
    }
  }

  &.center {
    & > div {
      text-align: center;
      height: auto;
    }
  }

  &.autoHeight {
    & > div {
      height: auto;
      position: relative;
      display: inline-block;
    }
  }

  &.slim {
    & > div {
      padding: 0 $size-s $size-s;
      @include screen-l {
        padding: 0 $size-l $size-s;
      }
    }
  }

  &.fullHeight {
    height: 100%;
    display: flex;

    &.vCenter {
      & > div {
        align-self: center;
        height: auto;
        position: relative;
      }
    }
  }

  &.float {
    @include float-width-full;
  }

  &.border {
    border: 1px solid $color-subtl1;
    background: $color-white;
  }

  &.dark {
    background: $color-dark;
    color: $color-white;
  }

  &.gradient {
    background: $color-gradient;
    color: $color-white;
  }

  &.scrollable {
    // overflow: auto;
    // & > div {
    // 	padding: 0;
    // }
    border: $size-s solid transparent;
    border-right: none;
    overflow: auto;

    & > div {
      // position: absolute;
      padding: 0 $size-s 0 0;
    }
    @include screen-l {
      border-width: $size-l;
      padding-bottom: calc((100% / (4 / 3)) - (#{$size-l} * 2));
      & > div {
        padding: 0 $size-l 0 0;
      }
    }
  }

  &.shadow {
    @include shadow-m;
  }
}
