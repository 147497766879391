@import '../../globals/mixins';

.searchOptions {
  background: $color-white;
  height: calc(100vh - (#{$size-s} * 11));
  padding: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  // padding-top: $size-s;

  @include screen-m() {
    height: 55vh;
    // padding-top: $size-l;
    padding-bottom: $size-l;
  }

  p {
    font-size: $font-size-s;
    color: $color-grey;
    user-select: none;
    text-align: initial;

    @include screen-m() {
      padding-top: $size-xs;
    }
  }

  & > div {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include autoscroll();

    p:first-child:not(:only-child) {
      color: $color-grey;
    }

    & > .checkTypes {
      padding: 0 !important;

      &.types-2 {
        & > div {
          max-width: calc((100% / 2));
        }
      }

      &.types-3 {
        & > div {
          max-width: calc((100% / 3));
        }
      }
    }

    & > .searchProps {
      padding: $size-l calc((100% / 8) - #{$size-xs});
      &.disabled {
        opacity: 0.5;
      }
    }

    & > .float {
      display: inline-block;
      width: 100%;
      text-align: center;
      float: none;
      h2 {
        margin-left: $size-xs;
        text-align: left;
      }
      &:last-child {
        padding-top: $size-s;
        margin-bottom: 200px;
      }
    }
  }
}
