@import "../../globals/mixins";

.footer {
  margin-bottom: $size-m;
  position: relative;
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;

  ul {
    @include no-list;
    border-top: 1px solid $color-subtl1;
    margin-bottom: 80px;
    width: 100%;

    & > li {
      @include no-list;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: $color-dark;
        }
      }

      &:first-child {
        height: 100px;
        padding: $size-s 0 $size-m;

        & > span {
          display: inline-block;
          height: $size-m;
          width: $size-m;
        }

        b {
          margin: $size-s 0;
          display: inline-block;
          width: 100%;
        }
      }

      &:not(:first-child) {
        padding: $size-xs 0;
      }
    }
  }

  // nav > div > div > div:nth-child(n + 2) {
  // 	ul {
  // 		border: 1px solid red;
  // 	}
  // }
}
