@import '../../globals/mixins';

.img {
  @include rounded;
  background: $color-subtl3;
  display: block;
  // position: relative;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .ratio {
    position: absolute;
  }
  &.contain {
    background: $color-white;
    position: relative;
  }
  &.error {
    color: $color-red;
  }
}
