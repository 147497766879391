@import '../../globals/mixins';

.input {
  display: inline-block;
  margin-top: $size-xs;
  margin-bottom: $size-xs;
  position: relative;
  text-align: left;

  input,
  textarea,
  select {
    @include transition-all;
    @include rounded;
    @include placeholder-color($color-subtl1);
    @include placeholder-size($font-size-xs);
    background: none;
    border: 1px solid $color-subtl1;
    color: $color-dark;
    float: left;
    font-size: $font-size-xs;
    height: $size-xl;
    margin: 0;
    padding: 0 $size-l;
    position: relative;
    text-overflow: ellipsis;
    width: 100%;
  }

  select {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' style='height:16px;width:16px' display='block' fill='none' stroke='currentcolor' stroke-width='4' overflow='visible'%3E%3Cpath d='M28 12 16.707 23.293a1 1 0 0 1-1.414 0L4 12'/%3E%3C/svg%3E");
    background-position-x: 100%;
    background-repeat: no-repeat;
    background-position: right $size-s center;
    background-size: $size-s;
    color: transparent;
    option {
      color: $color-dark;
    }
    // select {
    // 	&:focus {
    // 		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' style='height:16px;width:16px;transform:scale(1, -1)' display='block' fill='none' stroke='currentcolor' stroke-width='4' overflow='visible'%3E%3Cpath d='M28 12 16.707 23.293a1 1 0 0 1-1.414 0L4 12'/%3E%3C/svg%3E");
    // 	}
    // }
  }

  & > span {
    position: absolute;
    width: $size-sm;
    height: $size-sm;
    left: 0;
    top: 50%;
    transform: translate($size-s, -50%);
    svg {
      @include transition-all;
      stroke: $color-subtl1;
    }
  }

  textarea {
    align-self: flex-start;
    border: none;
    resize: none;
    height: 100%;
  }

  & > label {
    @include transition-all;
    color: $color-dark;
    font-size: $font-size;
    left: 0;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    padding: 0 $size-xxs 0 $size-l;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &.mandatory > label {
    &::after {
      content: ' *';
    }
  }

  & > button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $size-xs;
    @include screen-m {
      right: $size-s;
    }
  }

  & > span:last-child {
    // error msg
    display: none;
  }

  & > div:last-of-type {
    display: flex;
    align-items: center;
    position: absolute;
    top: $size-m;
    right: $size-s;
    transform: translateY(-44%);
    z-index: 4;
    & > button {
      display: inline-block;
      position: relative;
      &:not(:only-child) {
        margin: 0 $size-xs 0 0;
      }
    }
  }

  &.textarea {
    @include rounded;
    border: 1px solid $color-subtl1;
    border-radius: $size-xs;
    height: 9em;
    padding: 21px 0 $size-s;
    & > small {
      position: absolute;
      top: $size-xxs;
      right: 20px;
      & > span {
        color: $color-grey;
      }
    }

    &.isMax {
      & > small {
        & > span {
          color: $color-dark;
        }
      }
    }
    & > span {
      top: 19px;
      transform: translateX($size-s);
    }

    & > label {
      top: $size-sm;
      transform: none;
    }

    &.focus {
      border-color: $color-dark;
      & > label {
        top: 3px;
      }
    }

    &.error {
      border-color: $color-red;
    }
  }

  .switch {
    @include transition-all;
    display: inline-block;
    position: relative;
    cursor: pointer;

    text-indent: $size-xl;
    line-height: 0.8;
    color: $color-grey;
    border: 1px solid $color-subtl1;
    border-radius: $size-xs;

    width: $size-l;
    height: $size-s;
    white-space: nowrap;
    z-index: 2;
    &:hover {
      border-color: $color-dark;
    }
    &::before {
      @include transition-all;
      position: absolute;
      content: '';
      border-radius: $size-xs;
      height: 10px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: $color-subtl1;
    }
  }

  &.checked .switch:before,
  input:checked + .switch:before {
    transform: translateX(22px);
  }

  &.active {
    // border-color: $color-dark;
    // fieldset {
    // 	label {
    // 		color: $color-dark;
    // 	}
    // 	input {
    // 		color: $color-white;
    // 		background: $color-dark;
    // 	}
    // }
    .switch {
      color: $color-dark;
      border-color: $color-dark;
      &::before {
        background-color: $color-dark;
      }
    }
  }

  &.add {
    & > label {
      color: $color-grey;
      &::before {
        content: '+ ';
      }
    }
  }

  &.info {
    & > small:last-child {
      color: $color-grey;
      position: absolute;
      left: $size-xxs;
      bottom: 0;
      transform: translateY($size-sm);

      right: unset;
      z-index: 2;
    }
  }

  &.dark {
    input,
    textarea,
    select {
      background-color: $color-dark;
      border-color: $color-dark;
      color: $color-white;
    }
  }

  &.grey {
    input,
    textarea,
    select {
      background-color: $color-subtl3;
      border-color: $color-dark;
    }
    &.textarea {
      background-color: $color-subtl3;
      &:not(.error) {
        border-color: $color-subtl3;
      }

      &.full {
        padding-top: $size-l;
        textarea {
          padding-left: $size-sm;
        }
        & > label,
        & > span {
          display: none;
        }
        & > small {
          left: $size-sm;
          top: $size-ss;
          right: unset;
          z-index: 2;
        }
      }
    }
  }

  &.white {
    input,
    textarea,
    select {
      background-color: $color-white;
      border-color: $color-subtl1;
    }
  }

  &.transparent {
    input,
    textarea,
    select {
      background-color: none;
      border-color: transparent;
    }
  }

  &.error {
    input,
    textarea,
    select {
      @include placeholder-color($color-red);
      border-color: $color-red;
    }
  }

  &.disabled {
    pointer-events: none;
    label,
    input:not([type='date']),
    textarea,
    select {
      color: $color-grey !important;
      bordercolor: $color-subtl1 !important;
    }
    svg {
      stroke: $color-subtl1 !important;
    }
  }

  &.date {
    input {
      color: transparent;
    }
    input[type='date'][value='']:in-range::-webkit-datetime-edit-year-field,
    input[type='date'][value='']:in-range::-webkit-datetime-edit-month-field,
    input[type='date'][value='']:in-range::-webkit-datetime-edit-day-field,
    input[type='date'][value='']:in-range::-webkit-datetime-edit-text {
      color: transparent;
    }
    input[type='date']:in-range::-webkit-datetime-edit-year-field,
    input[type='date']:in-range::-webkit-datetime-edit-month-field,
    input[type='date']:in-range::-webkit-datetime-edit-day-field,
    input[type='date']:in-range::-webkit-datetime-edit-text {
      background-color: transparent;
      color: black;
    }

    input[type='date']::-webkit-calendar-picker-indicator {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      color: transparent;
      background: blue;
    }
  }

  &.focus {
    &.date {
      input {
        color: initial;
      }
    }
    & > label {
      color: $color-grey;
      font-size: $font-size-xs;
      padding-right: $size-xxs;
      overflow: hidden;
      text-overflow: ellipsis;
      top: 4px;
      transform: translateY(0);
      white-space: nowrap;
      &::before {
        content: '';
      }
    }

    svg {
      stroke: $color-dark;
    }

    input,
    select,
    textarea {
      border-color: $color-dark;
      font-size: $font-size;
    }
    select {
      color: $color-dark;
    }

    &.error {
      input,
      select,
      textarea {
        border-color: $color-red;
      }
      & > label {
        color: $color-red;
      }
    }
  }

  &.suggestions {
    z-index: 4;

    &.expanded {
      input {
        border-bottomcolor: $color-subtl1;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      & > ul {
        border-top: none;
        border-color: $color-dark;
        max-height: 25vh;
        padding: $size-xs 0;
        overflow-y: auto;
      }
    }

    &:not(.focus) {
      & > label {
        top: $size-sm;
        transform: none;
      }
    }

    &.focus {
      border-color: $color-dark;
    }
    // &.expanded {
    // 	@include rounded;
    // 	border: 1px solid $color-subtl1;
    // 	border-radius: $size-xs;
    // 	padding: $size-sm 0 $size-s;
    // 	&.focus {
    // 		border-color: $color-dark;
    // 	}
    & > span {
      top: 20px;
      transform: translateX($size-s);
    }

    & > ul {
      @include rounded;
      @include transition-all;
      background: $color-white;
      border: 1px solid transparent;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: auto;
      margin: 0;
      max-height: 0;
      overflow-y: hidden;
      position: absolute;
      top: $size-xl;
      width: 100%;
      z-index: 2;

      & > li {
        z-index: 4;
        align-items: center;
        cursor: pointer;
        color: $color-dark;
        display: flex;
        padding: 0 $size-xs 0 $size-l;
        position: relative;
        min-height: $size-l;
        width: 100%;
        text-decoration: none;

        & > span {
          line-height: 1.4;
        }

        & > span:first-of-type:not(:only-of-type) {
          position: absolute;
          width: $size-sm;
          height: $size-sm;
          top: $size-ss;
          left: $size-ss;
          svg {
            stroke: $color-subtl1;
          }
        }

        & > span:last-of-type {
          & > span:last-of-type {
            @include transition(color);
            color: $color-grey;
          }
        }

        & > span {
          svg {
            & > * {
              @include transition(stroke);
            }
          }
        }

        &:hover {
          & > span:last-of-type {
            & > span:last-of-type {
              color: $color-dark;
            }
          }
          & > span {
            svg {
              & > * {
                stroke: $color-dark;
              }
            }
          }
        }
      }
    }
  }

  &:not(.float) {
    @include flex-left-center;
    width: 100%;
  }

  &.full {
    @include float-width-full;
  }

  &.third {
    @include float-width-full;
    @include screen-m {
      &:not(.full) {
        @include float-width-l;
      }
    }
  }

  &.half {
    @include float-width-full;
    @include screen-m {
      &:not(.full) {
        @include float-width-m;
      }
    }
  }

  &.float {
    @include float-width-full;
    float: left;

    @include screen-m {
      &:not(.full):not(.half):not(.third) {
        @include float-width-m;
      }
    }

    @include screen-l {
      &:not(.full):not(.half):not(.third) {
        @include float-width-s;
      }
    }
  }
}

/*

input[type="date"] {
    position: relative;
}

// create a new arrow, because we are going to mess up the native one
//see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. 
input[type="date"]:after {
  content: "\25BC"; 
  color: #555;
  padding: 0 5px;
}

// change color of symbol on hover 
input[type="date"]:hover:after {
  color: #bf1400;
}

// make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

// adjust increase/decrease button 
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}

// adjust clear button 
input[type="date"]::-webkit-clear-button {
   z-index: 1;
}
*/
