@import '../../globals/mixins';

.tutorial {
  max-width: 500px;
  margin-top: -$size-xs;
  min-width: 240px;
  @include screen-m {
    margin-top: -$size-s;
  }

  video {
    @include rounded;
    border: 1px solid $color-subtl1;
    margin: $size-xs 0;
    position: relative;
    width: 100%;
  }

  & > div:nth-child(2) {
    padding: 0;
    @include screen-m {
      padding: $size-xs 12% 0;
    }
  }

  & > ul {
    @include dot-navigation;
    position: relative;
    text-align: center;
    border: none;
    & > li {
      button {
        color: $color-subtl3;
      }
      &.active {
        button {
          color: $color-grey;
        }
      }
    }
  }

  @media (min-height: 1800px) {
    max-width: 632px;
    & > ul {
      margin-bottom: $size-m;
    }
  }
}
