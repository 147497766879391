@import '../../globals/mixins';

.offcanvas {
  @include transition-all();
  height: 1px;
  overflow: visible;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;

  &:before {
    @include transition-all();
    content: ' ';
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .btn {
    background: none;
    height: $size-m;
    position: absolute;
    margin-top: $size-xs;
    right: $size-s;
    top: $size-l;
    width: $size-m;
    z-index: 3;

    @include screen-m() {
      right: $size-m;
    }

    @include screen-larger-grid() {
      right: 50vw;
      transform: translateX(calc(#{$grid-half-width} - #{$size-s}));
    }

    svg {
      * {
        -webkit-transition: all 0.1s;
        transition: all 0.1s;
        stroke: $color-white;
      }
    }
  }

  &.no_btn {
    .btn {
      display: none;
    }
  }

  & > div {
    @include transition-all();
    background: $color-white;
    -webkit-box-shadow: 0 0 $size-s 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 $size-s 0 rgba(0, 0, 0, 0);
    height: 100vh;
    position: fixed;
    right: -80%;
    margin: 0;
    overflow-y: auto;
    padding: $size-xxxxl 8% 0;
    text-align: left;
    width: 80%;
    z-index: 2;

    //Avatar
    // & > div {
    //   margin-top: $size-l;
    // }

    & > button {
      width: 100% !important;
    }

    @include screen-m {
      right: -50%;
      width: 50%;
    }

    @include screen-l {
      & > button {
        width: calc(66% - #{$size-s}) !important;
      }
    }

    @include screen-xl {
      & > button {
        width: calc(50% - #{$size-s}) !important;
      }
    }
  }

  // Meta / Close
  & > div > ul:first-of-type {
    @include transition-all;
    background-position: 0 100%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 1) 50%
      )
      left;
    background-size: 200%;
    height: calc(#{$size-xxxl} + #{$size-s});
    margin-top: 0;
    padding-top: 48px;
    pointer-events: none;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: calc(80% - $size-s);
    z-index: 2;

    & > li {
      display: inline-block !important;
    }

    & > li:last-child {
      padding-left: $size-sm;
      padding-right: $size-m;
      svg {
        & > g {
          transform: translate(8px, 14px);
        }
      }
    }

    // @include screen-s {
    //   & > li:last-child {
    //     padding-left: 27px;
    //     padding-right: $size-sm;
    //   }
    // }

    @include screen-m {
      width: calc(50% - $size-xs);
      & > li:last-child {
        padding-left: $size-sm;
        padding-right: $size-ml;
      }
    }

    @include screen-l {
      background: none;
    }

    @include screen-xl {
      & > li:last-child {
        padding-right: 40px;
      }

      right: 50vw;
      transform: translateX(calc(680px - #{$size-sm}));
      width: 648px;
    }

    @include screen-larger-grid() {
      & > li:last-child {
        padding-right: 40px;
      }
      transform: translateX(calc(680px + #{$size-s}));
    }
  }

  &.scrolled > div > ul:first-of-type {
    height: $size-l;
    padding-top: 0;
    & > li:last-child {
      padding-right: $size-sm;
      padding-left: $size-s;
    }
    @include screen-m {
      & > li:last-child {
        padding-right: $size-m;
      }
    }
    @include screen-xl {
      & > li:last-child {
        padding-right: $size-m;
      }
    }

    @include screen-larger-grid {
      & > li:last-child {
        padding-right: 52px;
      }
    }
  }

  &.open > div > ul:first-of-type {
    pointer-events: all;
    opacity: 1;
    background-position: right;
  }

  nav {
    ul {
      display: block;
      margin: $size-m 0 0;
      padding: 0;
      @include screen-m() {
        margin-top: $size-l;
      }
      li {
        display: block;
        height: auto;
        opacity: 1;
        padding: $size-s 0;
      }
    }

    &:first-of-type {
      ul {
        li {
          @include font-size-h2;
        }
      }
    }

    &:last-child {
      ul {
        margin: $size-m 0;
      }
    }
    &:nth-last-of-type(3) {
      ul {
        margin-top: 0 !important;
      }
    }
  }

  &.scrolled {
    .btn {
      top: 0;
    }
    & > div {
      padding: $size-xxxl 8% 0;
      & > ul {
        margin-top: 0;
      }
    }
  }

  &.open {
    & > div {
      @include shadow-s();
    }
    &::before {
      // background: rgba(0,0,0,0.5);
      pointer-events: all;
    }
    .btn {
      svg {
        * {
          stroke: $color-dark;
        }
      }
      &:hover {
        svg {
          * {
            stroke: $color-blue;
          }
        }
      }
    }
    &.no_btn {
      .btn {
        display: block;
      }
    }
    & > div {
      right: 0;
    }
  }
}
