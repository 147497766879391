@import "../../globals/mixins";

.content {
  // main
  padding-bottom: $size-xl;
  padding-top: calc(#{$size-l} * 3);
  min-height: calc(100vh - (#{$size-l} * 3)); //56vh;//120vh;
  width: 100vw;
  z-index: 1;
  & > div {
    // Container
    @include transition(opacity);
    & > section:only-of-type {
      margin-top: 0 !important;
    }
  }
  &.loading {
    @include loadingBg;
    & > div {
      opacity: 0.1;
    }
  }
  &.hasStage {
    padding-top: 0;
  }
}
