@import '../../globals/mixins';

.loginForm {
  height: auto;
  width: 100%;

  &.asPage {
    text-align: left;
    p {
      max-width: none;
    }
  }

  &.step-2,
  &.step-3,
  &.step-4 {
    p {
      padding: $size-xl 0 !important;
    }
  }

  form {
    padding-bottom: $size-m;

    // fieldset {
    // 	padding: $size-m 0 0;
    // }

    & > small,
    & > span {
      display: block;
      float: left;
      width: 100%;
    }

    & > small {
      color: $color-red;
    }

    & > span {
      color: $color-grey;
      margin-bottom: $size-s;
    }

    & > button {
      transform: translate(-50%, $size-xs);
      position: relative;
      left: 50%;
    }
  }
}
