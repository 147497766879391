@import '../../globals/mixins';

.signupForm {
  height: auto;
  width: 100%;

  &.step-1 {
    p {
      padding: $size-xl 0 !important;
    }
    button {
      display: none;
    }
  }

  // &.step-2 {
  // 	fieldset {
  // 		& > div {
  // 			&:nth-child(1),
  // 			&:nth-child(2) {
  // 				width: calc(50% - #{$size-xs});
  // 			}
  // 			&:nth-child(1){
  // 				margin-right: $size-xs;
  // 			}
  // 			&:nth-child(2){
  // 				margin-left: $size-xs;
  // 			}
  // 		}
  // 	}
  // }
}
