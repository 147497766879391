@import "../../globals/mixins";

.searchTypeCheck {
  background-color: $color-white;
  border-bottom-left-radius: $size-xs;
  border-bottom-right-radius: $size-xs;
  bottom: 0;
  -webkit-box-shadow: inset 0px 0px $size-m 0px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 0px $size-m 0px rgba(0, 0, 0, 0.2);
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 0;
  list-style: none;
  display: inline-flex;
  float: left;
  height: 112px;
  width: 100%;
  margin: 0;
  position: relative;

  &:first-child {
    border-bottom-left-radius: 0;
  }
  &:last-child {
    border-bottom-right-radius: 0;
  }

  @include transition-all();

  & > span {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    svg {
      @include transition-all();
      stroke: $color-subtl1;
    }
  }

  & > label {
    display: flex;
    position: absolute;
    bottom: $size-s;
    left: auto;
    width: auto;
    white-space: nowrap;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    // padding-left: 25px;

    input[type="checkbox"] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
      height: $size-s;
      width: $size-s;
      border-radius: 4px;
      border: 1px solid $color-subtl1;
      background-color: $color-white;
      margin-right: $size-xs;
      margin-top: 0 !important;
      &:checked {
        border-color: $color-dark;
        background-color: $color-dark;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='none' d='M0 0h48v48H0z'/%3E%3Cpath d='m11.452 25 10.552 7.118L34 14.333' stroke='%23ffffff' stroke-miterlimit='10' stroke-width='4' fill='none'/%3E%3C/svg%3E");
      }
    }
    small {
      display: flex;
    }
  }

  &.active {
    -webkit-box-shadow: none;
    box-shadow: none;
    svg {
      stroke: $color-dark;
    }
  }

  @include screen-l {
    & {
      justify-content: space-evenly;
    }
    & > span {
      top: unset;
      transform: none;
      display: inline-flex;
      width: $size-xl;
      height: $size-xl;
      position: relative;
    }
    & > label {
      display: inline-flex;
      position: relative;
      bottom: auto;
      padding: 0;
      @include font-size-p;
      input[type="checkbox"] {
        margin-right: $size-s;
        height: $size-m;
        width: $size-m;
      }
    }
  }
}
