@import '../../globals/mixins';

.checkbox {
  align-items: flex-start;
  display: inline-flex;
  margin-bottom: $size-s;
  position: relative;
  width: 100%;

  &.float {
    margin: $size-xs $size-m $size-s 0;
    width: auto;
  }

  input {
    background: none;
    border: 1px solid $color-subtl1;
    border-radius: $size-xxs;
    cursor: pointer;
    margin: 0;
    position: relative;
    &:checked {
      border-color: $color-dark;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath vector-effect='non-scaling-stroke' d='m11.452 25 10.552 7.118L34 14.333' fill='none' stroke='%23001640' stroke-width='2'/%3E%3C/svg%3E");
    }
  }

  & > label {
    color: $color-dark;
    cursor: pointer;
    margin-top: $size-xxs;
    font-size: $font-size;
    padding: 0 0 0 $size-l;
  }

  &.center {
    display: inline-block;
    text-align: center;
    padding: 0;
    margin: 0;
    & > label {
      width: 100%;
      display: inline-block;
      text-align: center;
      position: relative;
      padding: 0;
      margin: $size-s 0;
    }
  }

  &.dark {
    input {
      &:checked {
        background-color: $color-dark;
        border-color: $color-dark;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath vector-effect='non-scaling-stroke' d='m11.452 25 10.552 7.118L34 14.333' fill='none' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E");
      }
    }
  }

  &.grey {
    input {
      background-color: $color-subtl3;
      border-color: $color-dark;
    }
  }

  &.white {
    input {
      background-color: $color-white;
      bordercolor: $color-subtl1;
    }
  }

  &.error {
    input,
    & > label {
      border-color: $color-red;
      color: $color-red;
    }
  }

  &.small {
    input {
      height: $size-sm;
      width: $size-sm;
    }
    & > label {
      @include font-size-small;
      padding: 0 0 0 $size-xs;
    }
  }

  &.medium {
    input {
      height: $size-m;
      width: $size-m;
    }
    &:not(.center) {
      & > label {
        padding: 0 0 0 $size-xs;
      }
    }
  }

  &.full {
    @include float-width-full;
  }

  &.third {
    @include float-width-full;
    @include screen-m {
      &:not(.full) {
        @include float-width-l;
      }
    }
  }

  &.half {
    @include float-width-full;
    @include screen-m {
      &:not(.full) {
        @include float-width-m;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}
