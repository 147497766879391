@import "../../globals/mixins";

.section {
  margin-top: $size-m;
  height: auto;

  h1 {
    & + p {
      margin-top: -$size-xs;
      padding: 0 0 $size-m;
      max-width: 50%;
    }
  }

  &.hero {
    h1 {
      @include font-size-hero;
    }
  }

  &.center {
    & > div {
      p {
        margin: auto;
      }

      text-align: center;
    }
  }

  @include screen-m {
    margin-top: $size-l;
    &.hero {
      h1 {
        max-width: 66.66666%;
      }
    }
  }

  @include screen-l {
    margin-top: $size-xl;
    &.hero {
      h1 {
        max-width: 50%;
      }
    }
  }
}
